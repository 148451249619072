<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-10">
          <h1>
            ДОРОГОЙ (-АЯ) КОЛЛЕГА!
          </h1>
        </div>
      </div>

      <h3>Компания Recordati рада приветствовать Вас!</h3>

      <p>
        За Вашу эффективную работу и с благодарностью за победу в Номинации «За Превосходный результат» Компания
        с признательностью дарит Вам возможность выбрать Подарочные сертификаты от сервиса Giftery.
      </p>

      <p>
        Получение сертификатов возможно в любое удобное время при положительном балансе.
        Выбранный сертификат будет доставлен вам на указанный e-mail сразу после подтверждения выбора.
      </p>

      <p>Вы можете выбирать любое количество сертификатов в день без ограничений.</p>

      <p>Информация о сроке действия и правила использования сертификата указаны при выборе подарка и на самом сертификате.</p>

      <hr>

      <p>В случае возникновения вопросов Вы всегда можете обратиться в нашу службу поддержки удобным вам способом:</p>
      <p>
        Email: <a href="mailto:support@giftery.ru">support@giftery.ru</a>,<br>
        горячая линия: <a href="tel:88007009090">8-800-700-90-90</a>
      </p>
      <p>С пожеланиями дальнейших успехов!</p>
      <br>
      <router-link class="button button--large button--rounded" :to="{ name: 'Catalog' }">Выбрать подарок</router-link>
      <br>
      <br>
    </div>
  </main>
</template>

<script>
import 'base/components/Cabinet'

export default {
  name: 'Cabinet',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--cabinet')
  }
}
</script>

<style lang="css" scoped>
  .buttons {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    width: 80%;
  }

  .buttons a {
    display: flex;
    text-align: center;
    font-size: 20px;
    padding: 1em;
    width: 32.3%;
    margin-bottom: 15px;
  }

  .buttons a span {
    display: block;
    margin: auto;
    line-height: 1.2;
  }

  @media (max-width: 991px) {
    .buttons {
      flex-wrap: wrap;
      width: auto;
    }

    .buttons a {
      width: 32.3%;
    }
  }

  @media (max-width: 575px) {
    .buttons {
      display: block;
    }

    .buttons a {
      width: auto;
    }
  }

  .text-danger {
    color: #c00;
    font-weight: bold;
  }
</style>
